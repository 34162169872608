import React from "react";
import SiteLayout from "../components/SiteLayout";
import CalculatorContainer from "../calc/CalculatorContainer";

import Breadcrumb from "../components/BreadCrumb/";
import Title from "../components/Title/";
import Block from "../components/Block/";
import Seo from "../components/Seo";

export default class PricePage extends React.Component {
    render() {
        return (
            <SiteLayout location={this.props.location}>
                <Seo title={"Vývoj cen"} />
                <Breadcrumb
                    crumbs={[{ label: "Vývoj cen", link: "vyvoj-cen" }]}
                />
                <Block leftIndent>
                    <Title type={"h1"} color={"gold"} mb={40}>
                        Podívejte se na hodnotu <br />
                        investice do zlata v čase
                    </Title>
                </Block>
                <Block radialGradient={true}>
                    <CalculatorContainer
                        prevPath={
                            this.props.location.state &&
                            this.props.location.state.prevPath &&
                            this.props.location.state.prevPath
                        }
                    />
                </Block>
            </SiteLayout>
        );
    }
}
