/*
 Calculate FV.
 Exact same Excel FV function
 Rate is the interest rate per period.
 Nper is the total number of payment periods in an annuity.
 Pmt is the payment made each period; it cannot change over the life of the annuity. Pmt must be entered as a negative number.
 Pv is the present value, or the lump-sum amount that a series of future payments is worth right now. If pv is omitted, it is assumed to be 0 (zero). PV must be entered as a negative number.
 Type is the number 0 or 1 and indicates when payments are due. If type is omitted, it is assumed to be 0 which represents at the end of the period.  If payments are due at the beginning of the period, type should be 1.
 */

export const calculateFv = (rate, nper, pmt, pv, type) => {
    pmt = -Math.abs(parseInt(pmt, 10));

    if (type === 1) {
        pv = -Math.abs(parseInt(pv, 10));
    } else {
        pv = Math.abs(parseInt(pv, 10));
    }

    nper = parseInt(nper * 12, 10);

    let pow = Math.pow(1 + rate, nper),
        fv;

    pv = pv || 0;
    type = type || 0;

    if (rate) {
        fv = (pmt * (1 + rate * type) * (1 - pow)) / rate - pv * pow;
    } else {
        fv = -1 * (pv + pmt * nper);
    }
    return parseInt(fv.toFixed(0), 10);
};

/*
 Nper is the total number of payment periods in an annuity.
 Pmt is the payment made each period; it cannot change over the life of the annuity. Pmt must be entered as a negative number.
 Pv is the present value, or the lump-sum amount
 */
export const investmentSum = (nper, pmt, pv, np) => {
    if ("month" == np) {
        return parseFloat(nper * 12 * pmt) + parseFloat(pv);
    } else {
        return parseFloat(pmt);
    }
};

export const getNperToString = years => {
    if (parseInt(years, 10) === 1) {
        return "rok";
    } else if (parseInt(years, 10) < 5) {
        return "roky";
    } else {
        return "let";
    }
};

const goldPrice = [
    { price: "6645", increase: "350" },
    { price: "12475", increase: "600" },
    { price: "24373", increase: "1200" },
    { price: "36577", increase: "1500" },
    { price: "57373", increase: "1900" },
    { price: "57373", increase: "1900" },
    { price: "111953", increase: "2500" },
    { price: "111953", increase: "2500" },
    { price: "275930", increase: "5000" },
    { price: "550555", increase: "7500" },
    { price: "1095353", increase: "10000" },
];

export const getGoldPrice = (nper, pmt, pv) => {
    let price = pv + pmt * 12;

    return goldPrice.reduce(
        (accumulator, currentValue, currentIndex, array) => {
            if (price >= currentValue.price) {
                return currentValue;
            } else {
                return accumulator;
            }
        },
        {}
    );
};

export const getIncreasePrice = (nper, pmt, pv, sum) => {
    let goldPrice = getGoldPrice(nper, pmt, pv);
    let goldPiece = sum / goldPrice.price;
    return Math.floor(goldPiece) * goldPrice.increase;
};

export const ratings = {
    10: {
        inflation: 1.91,
        rate: 5.4,
        sum: 0,
    },
    20: {
        inflation: 2.6,
        rate: 6.14,
        sum: 0,
    },
    30: {
        inflation: 4.29,
        rate: 4.19,
        sum: 0,
    },
};

export const getParameter = (years, value) => {
    if (years >= 20) {
        return ratings[30][value];
    } else if (years >= 10) {
        return ratings[20][value];
    } else {
        return ratings[10][value];
    }
};

export const getRange = (start, max, iteration) => {
    let range = [];
    for (let n = start; n <= max; n += iteration) {
        range.push(n);
    }
    return range;
};

export const getPeriodInterest = (rate, periods, pmt, pv, type) =>
    periods.map(period => calculateFv(rate, period, pmt, pv, type));

export const getPlotlyLineData = (nper, pmt, pv, np) => {
    let lines = [
        {
            type: "rate",
            color: "#beac76",
            fill: "tonexty",
        },
        {
            type: "inflation",
            color: "#00212f",
            fill: "none",
        },
        {
            type: "sum",
            color: "#8e8e74",
            fill: "tozeroy",
        },
    ];

    let actualYear = new Date().getFullYear();
    let yearRange = getRange(actualYear, actualYear + nper, 1);
    let range = getRange(0, nper, 1);
    let rating;
    let type = 0;

    if ("all" == np) {
        type = 1;
        pv = Math.abs(parseInt(pmt, 10));
        pmt = 0;
    }

    return lines.map(line => {
        rating = getParameter(nper, line.type) / 100 / 12;
        return {
            x: yearRange,
            y: getPeriodInterest(rating, range, pmt, pv, type),
            mode: "lines",
            name: "exp",
            fill: line.fill,
            line: {
                color: line.color,
                shape: "spline",
                size: 10,
            },
            hoverinfo: "none",
            hovertext: "",
        };
    });
};

let yearNow = new Date().getFullYear();

export const getShapes = (xtext, actualYear) => [
    {
        type: "line",
        layer: "below",
        line: {
            color: "#dedede",
            width: 1,
            dash: "line",
        },
        xref: "x",
        x0: "test",
        x1: yearNow,
        yref: "paper",
        y0: 0,
        y1: 1,
    },
    {
        type: "line",
        layer: "below",
        line: {
            color: "#dedede",
            width: 1,
            dash: "dot",
        },
        xref: "x",
        x0: xtext,
        x1: xtext,
        yref: "paper",
        y0: 0,
        y1: 1,
    },
    xtext !== actualYear
        ? {
              type: "line",
              layer: "below",
              line: {
                  color: "#dedede",
                  width: 1,
                  dash: "dot",
              },
              xref: "x",
              x0: actualYear,
              x1: actualYear,
              yref: "paper",
              y0: 0,
              y1: 1,
          }
        : undefined,
];

export const getAnotations = (xtext, actualYear) => [
    {
        xref: "x",
        x: yearNow,
        yref: "paper",
        y: 1.1,
        text: "<b>" + yearNow + "</b>",
        showarrow: false,
        font: { size: 11, color: "#d8f7fd" },
    },
    {
        xref: "x",
        x: xtext,
        yref: "paper",
        y: 1.1,
        text: "<b>" + xtext + "</b>",
        showarrow: false,
        font: { size: 11 },
    },
    xtext !== actualYear
        ? {
              xref: "x",
              x: actualYear,
              yref: "paper",
              y: 1.1,
              text: "<b>" + actualYear + "</b>",
              showarrow: false,
              font: { size: 11, color: "#bbb" },
          }
        : undefined,
];
