import React, { Component } from "react";

import {
    investmentSum,
    getNperToString,
    getPlotlyLineData,
    getParameter,
    getShapes,
    getAnotations,
    getIncreasePrice,
    calculateFv,
} from "./calculatorFunctions";

import Calculator from "./Calculator";

let actualYear = new Date().getFullYear();

class CalculatorContainer extends Component {
    state = {
        rate: 5.4,
        pmt: 1500,
        pv: 0,
        np: "month",
        nper: 15,
        nperslider: 15,
    };

    /**
     * @param event
     */
    handleFocus = event => {};

    /**
     * @param value
     */
    handleOnChange = value => {
        this.setState({
            nperslider: value,
            nper: value,
        });
    };

    /**
     * @param event
     */
    handleInputChange = event => {
        const target = event.target;
        const value =
            target.name !== "np"
                ? target.value.toString().replace(/[^0-9]/g, "")
                : target.value;
        const name = target.name;
        this.setState({
            [name]: value,
        });

        if (name == "nper") {
            this.setState({
                nperslider: value,
            });
        }
    };

    render = () => {
        let interestRate = getParameter(this.state.nper, "rate") / 100 / 12;

        let iSum = investmentSum(
            this.state.nper,
            this.state.pmt,
            this.state.pv,
            this.state.np
        );

        let increasePrice = getIncreasePrice(
            this.state.nper,
            this.state.pmt,
            this.state.pv,
            iSum
        );

        let type = 0;
        let pv = this.state.pv;
        let pmt = this.state.pmt;

        if ("all" == this.state.np) {
            type = 1;
            pv = Math.abs(parseInt(pmt, 10));
            pmt = 0;
        } else {
            pv = Math.abs(parseInt(increasePrice, 10));
        }

        let fv = calculateFv(interestRate, this.state.nper, pmt, pv, type);

        let goldProps = {
            hrate: getParameter(this.state.nper, "rate"),
            inflation: getParameter(this.state.nper, "inflation"),
            investmentSum: iSum,
            getNperToString: getNperToString(this.state.nper),
            iPrice: increasePrice,
            shapesData: getShapes(
                parseInt(actualYear, 10) + parseInt(this.state.nper, 10),
                parseInt(actualYear, 10) + parseInt(this.state.nper, 10)
            ),
            annotationData: getAnotations(
                parseInt(actualYear, 10) + parseInt(this.state.nper, 10),
                parseInt(actualYear, 10) + parseInt(this.state.nper, 10)
            ),
            plotlyData: getPlotlyLineData(
                this.state.nper,
                this.state.pmt,
                this.state.pv,
                this.state.np
            ),
            actualYear: actualYear,
            futureValue: fv,
        };

        return (
            <Calculator
                {...goldProps}
                {...this.state}
                handleInputChange={this.handleInputChange}
                handleOnChange={this.handleOnChange}
                prevPath={this.props && this.props.prevPath}
            />
        );
    };
}

export default CalculatorContainer;
