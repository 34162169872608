import React, { Component } from "react";
import Slider from "react-rangeslider";

import NumberFormat from "react-number-format";
import accounting from "accounting";
import Loadable from "react-loadable";

import Button from "../components/Button/index";
// To include the default styles
import "./vendor-override.css";
import cx from "classnames";
import styles from "./Calculator.module.sass";

import loader from "./loader.svg";

const Plot = Loadable({
    loader: () => import("react-plotly.js"),
    loading: () => (
        <div className={styles.loading}>
            <img src={loader} alt="Loading" />
        </div>
    ),
});
class Calculator extends Component {
    render() {
        return (
            <div>
                <form onSubmit={this.props.handleSubmit}>
                    <div className={styles.formGroupRow}>
                        <div
                            className={cx(
                                styles.formGroup,
                                styles.formGroupInline
                            )}
                        >
                            <label>Vklad</label>
                            <span>Ideálně 1/3 disponibilních příjmů</span>
                            <NumberFormat
                                isAllowed={values => {
                                    const {
                                        formattedValue,
                                        floatValue,
                                    } = values;
                                    return (
                                        formattedValue === "" ||
                                        floatValue <= 999999
                                    );
                                }}
                                name="pmt"
                                type="text"
                                value={this.props.pmt}
                                onChange={this.props.handleInputChange}
                                thousandSeparator=" "
                                suffix=" Kč"
                            />
                        </div>
                        <div
                            className={cx(
                                styles.formGroup,
                                styles.formGroupInline,
                                styles.formGroupSelect
                            )}
                        >
                            <label>Frekvence plateb</label>
                            <span>S výběrem Vám rádi pomůžeme</span>
                            <div>
                                <select
                                    name="np"
                                    className={styles.select}
                                    onChange={this.props.handleInputChange}
                                >
                                    <option value="month">měsíčně</option>
                                    <option value="all">jednorázově</option>
                                </select>
                            </div>
                        </div>

                        <div
                            className={cx(styles.formGroup, styles.formGroupNp)}
                        >
                            <label>Kolik let chcete investovat</label>
                            <NumberFormat
                                isAllowed={values => {
                                    const {
                                        formattedValue,
                                        floatValue,
                                    } = values;
                                    return (
                                        formattedValue === "" ||
                                        floatValue <= 30
                                    );
                                }}
                                name="nper"
                                type="text"
                                value={this.props.nper}
                                onChange={this.props.handleInputChange}
                                suffix={" " + this.props.getNperToString}
                            />
                        </div>
                        <div className={styles.slider}>
                            <div>
                                <label>Kolik let chcete investovat</label>
                                <Slider
                                    min={1}
                                    max={30}
                                    step={1}
                                    defaultValue={this.props.nperslider}
                                    value={this.props.nperslider}
                                    onChange={this.props.handleOnChange}
                                    name="nperslider"
                                    tooltip={false}
                                />
                            </div>
                            <div className={styles.sliderInfo}>
                                <span>1 rok</span>
                                <span>10 let</span>
                                <span>20 let</span>
                                <span>30 let</span>
                            </div>
                        </div>
                    </div>

                    <div className={styles.formBackground}>
                        <Plot
                            style={{ width: "100%" }}
                            data={this.props.plotlyData}
                            className={styles.plot}
                            layout={{
                                xaxis: {
                                    fixedrange: true,
                                    ticklen: 0,
                                    tickcolor: "#cdd5d9",
                                    linewidth: 0,
                                    linecolor: "#ffffff",
                                    spikethickness: 1,
                                    showgrid: false,
                                    automargin: true,

                                    tick0: this.props.actualYear,
                                    dtick: 5,

                                    tickfont: {
                                        size: 11,
                                        color: "#9aaab2",
                                    },
                                },
                                yaxis: {
                                    fixedrange: true,
                                    autorange: true,
                                    ticklen: 10,
                                    tickcolor: "#ffffff",
                                    ticksuffix: " Kč",
                                    tickfont: {
                                        size: 11,
                                        color: "#9aaab2",
                                    },
                                    exponentformat: "none",
                                    linewidth: 0,
                                    spikethickness: 1,
                                    showgrid: true,
                                    zeroline: false,
                                    zerolinecolor: "#ffffff",
                                    linecolor: "#ffffff",
                                },
                                hovermode: "x",
                                separators: ", ",
                                margin: {
                                    l: 100,
                                    t: 30,
                                    r: 0,
                                    b: 60,
                                    pad: 0,
                                },
                                showlegend: false,
                                font: {
                                    size: 11,
                                },
                                paper_bgcolor: "rgba(0,0,0,0)",
                                plot_bgcolor: "rgba(0,0,0,0)",
                                useResizeHandler: true,
                                autosize: true,
                            }}
                            config={{ displayModeBar: false, responsive: true }}
                            useResizeHandler={true}
                        />

                        <div className={styles.bottomInfo}>
                            <div className={styles.historicalInfo}>
                                <dl>
                                    <dt className={styles.historical}>
                                        Historické zhodnocení
                                    </dt>
                                    <dd>{this.props.hrate} %</dd>
                                    <dt className={styles.inflation}>
                                        Historická inflace
                                    </dt>
                                    <dd>{this.props.inflation} %</dd>
                                    <dt>Vratná záloha vaší investice</dt>
                                    <dd>
                                        {accounting.formatNumber(
                                            this.props.iPrice,
                                            0,
                                            " ",
                                            ","
                                        )}
                                        &nbsp;Kč
                                    </dd>
                                    <dt className={styles.investment}>
                                        Investované prostředky
                                    </dt>
                                    <dd>
                                        {accounting.formatNumber(
                                            this.props.investmentSum,
                                            0,
                                            " ",
                                            ","
                                        )}
                                        &nbsp;Kč
                                    </dd>
                                </dl>
                            </div>
                            <div className={styles.cta}>
                                <dl className={styles.results}>
                                    <dt className={styles.investmentInGold}>
                                        Hodnota investice ve zlatě
                                    </dt>
                                    <dd className={styles.investmentInGoldSum}>
                                        {accounting.formatNumber(
                                            this.props.futureValue,
                                            0,
                                            " ",
                                            ","
                                        )}
                                        &nbsp;Kč <sub>*</sub>
                                    </dd>
                                </dl>
                                <Button
                                    uri="/gold-form/"
                                    type="link"
                                    color="gold"
                                    width="full"
                                >
                                    Máte zájem investovat do zlata?
                                </Button>
                                {this.props.prevPath && (
                                    <Button
                                        mt={20}
                                        uri={this.props.prevPath}
                                        type="link"
                                        color={"outline-gold-dark"}
                                        width={"full"}
                                    >
                                        Zpět k&nbsp;nákupu
                                    </Button>
                                )}
                                <span className={styles.disclaimers}>
                                    *Přepočítáno podle historického vývoje
                                    hodnoty unce zlata dle zvoleného
                                    investičního horizontu. Jedná se pouze o
                                    odhad informativního charakteru založený na
                                    údajích z historického vývoje, nikoliv o
                                    žádnou formu garance či závazného prohlášení
                                    ze strany Kapitolu.
                                </span>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default Calculator;
